/**
 * 현재 미리캔버스 사이트에서 제공하는 "언어" 목록을 정의한 타입
 * @see http://www.lingoes.net/en/translator/langcode.htm
 */
export const LANGUAGE_TYPE_LIST = ['ko', 'en', 'ja', 'de', 'es', 'fr', 'it', 'nl', 'pt'] as const;

/**
 * 현재 서비스 중인 언어 LanguageType 저장하고 있는 배열
 * 언어별 탭을 그려주는 순서를 보장해주는 배열이기 때문에 새로운 언어가 추가될 경우 순서에 맞게 넣어주어야 함
 * @important single source of truth
 */
export type LanguageType = (typeof LANGUAGE_TYPE_LIST)[number];

/**
 * 'ko' 보단 LanguageEnum.KO 로 사용하길 추천
 */
export const LanguageEnum = {
  KO: 'ko',
  EN: 'en',
  JA: 'ja',
  DE: 'de',
  ES: 'es',
  FR: 'fr',
  IT: 'it',
  NL: 'nl',
  PT: 'pt',
} as const satisfies Record<Uppercase<LanguageType>, LanguageType>;

/**
 * ISO 3166 기준 국가 코드
 * @link https://www.iso.org/obp/ui/#search/code/
 */
export const COUNTRY_CODES = [
  'AF',
  'AL',
  'DZ',
  'AS',
  'AD',
  'AO',
  'AI',
  'AQ',
  'AG',
  'AR',
  'AM',
  'AW',
  'AU',
  'AT',
  'AZ',
  'BS',
  'BH',
  'BD',
  'BB',
  'BY',
  'BE',
  'BZ',
  'BJ',
  'BM',
  'BT',
  'BO',
  'BQ',
  'BA',
  'BW',
  'BV',
  'BR',
  'IO',
  'BN',
  'BG',
  'BF',
  'BI',
  'CV',
  'KH',
  'CM',
  'CA',
  'KY',
  'CF',
  'TD',
  'CL',
  'CN',
  'CX',
  'CC',
  'CO',
  'KM',
  'CD',
  'CG',
  'CK',
  'CR',
  'HR',
  'CU',
  'CW',
  'CY',
  'CZ',
  'CI',
  'DK',
  'DJ',
  'DM',
  'DO',
  'EC',
  'EG',
  'SV',
  'GQ',
  'ER',
  'EE',
  'SZ',
  'ET',
  'FK',
  'FO',
  'FJ',
  'FI',
  'FR',
  'GF',
  'PF',
  'TF',
  'GA',
  'GM',
  'GE',
  'DE',
  'GH',
  'GI',
  'GR',
  'GL',
  'GD',
  'GP',
  'GU',
  'GT',
  'GG',
  'GN',
  'GW',
  'GY',
  'HT',
  'HM',
  'VA',
  'HN',
  'HK',
  'HU',
  'IS',
  'IN',
  'ID',
  'IR',
  'IQ',
  'IE',
  'IM',
  'IL',
  'IT',
  'JM',
  'JP',
  'JE',
  'JO',
  'KZ',
  'KE',
  'KI',
  'KP',
  'KR',
  'KW',
  'KG',
  'LA',
  'LV',
  'LB',
  'LS',
  'LR',
  'LY',
  'LI',
  'LT',
  'LU',
  'MO',
  'MG',
  'MW',
  'MY',
  'MV',
  'ML',
  'MT',
  'MH',
  'MQ',
  'MR',
  'MU',
  'YT',
  'MX',
  'FM',
  'MD',
  'MC',
  'MN',
  'ME',
  'MS',
  'MA',
  'MZ',
  'MM',
  'NA',
  'NR',
  'NP',
  'NL',
  'NC',
  'NZ',
  'NI',
  'NE',
  'NG',
  'NU',
  'NF',
  'MK',
  'MP',
  'NO',
  'OM',
  'PK',
  'PW',
  'PS',
  'PA',
  'PG',
  'PY',
  'PE',
  'PH',
  'PN',
  'PL',
  'PT',
  'PR',
  'QA',
  'RO',
  'RU',
  'RW',
  'RE',
  'BL',
  'SH',
  'KN',
  'LC',
  'MF',
  'PM',
  'VC',
  'WS',
  'SM',
  'ST',
  'SA',
  'SN',
  'RS',
  'SC',
  'SL',
  'SG',
  'SX',
  'SK',
  'SI',
  'SB',
  'SO',
  'ZA',
  'GS',
  'SS',
  'ES',
  'LK',
  'SD',
  'SR',
  'SJ',
  'SE',
  'CH',
  'SY',
  'TW',
  'TJ',
  'TZ',
  'TH',
  'TL',
  'TG',
  'TK',
  'TO',
  'TT',
  'TN',
  'TM',
  'TC',
  'TV',
  'TR',
  'UG',
  'UA',
  'AE',
  'GB',
  'UM',
  'US',
  'UY',
  'UZ',
  'VU',
  'VE',
  'VN',
  'VG',
  'VI',
  'WF',
  'EH',
  'YE',
  'ZM',
  'ZW',
  'AX',
] as const;
export type CountryCode = (typeof COUNTRY_CODES)[number];
/**
 * 국가코드 Enum
 * @see https://countrycode.org/
 * KR: 대한민국
 * US: 미국
 * JP: 일본
 * CA: 캐나다
 * AU: 호주
 * DE: 독일
 * ES: 스페인
 * FR: 프랑스
 * IT: 이탈리아
 * NL: 네덜란드
 * PT: 포르투갈
 * CN: 중국
 * KE: 케냐
 * ... 등등의 값이 있다.
 */
export const CountryEnum = {
  AF: 'AF',
  AL: 'AL',
  DZ: 'DZ',
  AS: 'AS',
  AD: 'AD',
  AO: 'AO',
  AI: 'AI',
  AQ: 'AQ',
  AG: 'AG',
  AR: 'AR',
  AM: 'AM',
  AW: 'AW',
  AU: 'AU',
  AT: 'AT',
  AZ: 'AZ',
  BS: 'BS',
  BH: 'BH',
  BD: 'BD',
  BB: 'BB',
  BY: 'BY',
  BE: 'BE',
  BZ: 'BZ',
  BJ: 'BJ',
  BM: 'BM',
  BT: 'BT',
  BO: 'BO',
  BQ: 'BQ',
  BA: 'BA',
  BW: 'BW',
  BV: 'BV',
  BR: 'BR',
  IO: 'IO',
  BN: 'BN',
  BG: 'BG',
  BF: 'BF',
  BI: 'BI',
  CV: 'CV',
  KH: 'KH',
  CM: 'CM',
  CA: 'CA',
  KY: 'KY',
  CF: 'CF',
  TD: 'TD',
  CL: 'CL',
  CN: 'CN',
  CX: 'CX',
  CC: 'CC',
  CO: 'CO',
  KM: 'KM',
  CD: 'CD',
  CG: 'CG',
  CK: 'CK',
  CR: 'CR',
  HR: 'HR',
  CU: 'CU',
  CW: 'CW',
  CY: 'CY',
  CZ: 'CZ',
  CI: 'CI',
  DK: 'DK',
  DJ: 'DJ',
  DM: 'DM',
  DO: 'DO',
  EC: 'EC',
  EG: 'EG',
  SV: 'SV',
  GQ: 'GQ',
  ER: 'ER',
  EE: 'EE',
  SZ: 'SZ',
  ET: 'ET',
  FK: 'FK',
  FO: 'FO',
  FJ: 'FJ',
  FI: 'FI',
  FR: 'FR',
  GF: 'GF',
  PF: 'PF',
  TF: 'TF',
  GA: 'GA',
  GM: 'GM',
  GE: 'GE',
  DE: 'DE',
  GH: 'GH',
  GI: 'GI',
  GR: 'GR',
  GL: 'GL',
  GD: 'GD',
  GP: 'GP',
  GU: 'GU',
  GT: 'GT',
  GG: 'GG',
  GN: 'GN',
  GW: 'GW',
  GY: 'GY',
  HT: 'HT',
  HM: 'HM',
  VA: 'VA',
  HN: 'HN',
  HK: 'HK',
  HU: 'HU',
  IS: 'IS',
  IN: 'IN',
  ID: 'ID',
  IR: 'IR',
  IQ: 'IQ',
  IE: 'IE',
  IM: 'IM',
  IL: 'IL',
  IT: 'IT',
  JM: 'JM',
  JP: 'JP',
  JE: 'JE',
  JO: 'JO',
  KZ: 'KZ',
  KE: 'KE',
  KI: 'KI',
  KP: 'KP',
  KR: 'KR',
  KW: 'KW',
  KG: 'KG',
  LA: 'LA',
  LV: 'LV',
  LB: 'LB',
  LS: 'LS',
  LR: 'LR',
  LY: 'LY',
  LI: 'LI',
  LT: 'LT',
  LU: 'LU',
  MO: 'MO',
  MG: 'MG',
  MW: 'MW',
  MY: 'MY',
  MV: 'MV',
  ML: 'ML',
  MT: 'MT',
  MH: 'MH',
  MQ: 'MQ',
  MR: 'MR',
  MU: 'MU',
  YT: 'YT',
  MX: 'MX',
  FM: 'FM',
  MD: 'MD',
  MC: 'MC',
  MN: 'MN',
  ME: 'ME',
  MS: 'MS',
  MA: 'MA',
  MZ: 'MZ',
  MM: 'MM',
  NA: 'NA',
  NR: 'NR',
  NP: 'NP',
  NL: 'NL',
  NC: 'NC',
  NZ: 'NZ',
  NI: 'NI',
  NE: 'NE',
  NG: 'NG',
  NU: 'NU',
  NF: 'NF',
  MK: 'MK',
  MP: 'MP',
  NO: 'NO',
  OM: 'OM',
  PK: 'PK',
  PW: 'PW',
  PS: 'PS',
  PA: 'PA',
  PG: 'PG',
  PY: 'PY',
  PE: 'PE',
  PH: 'PH',
  PN: 'PN',
  PL: 'PL',
  PT: 'PT',
  PR: 'PR',
  QA: 'QA',
  RO: 'RO',
  RU: 'RU',
  RW: 'RW',
  RE: 'RE',
  BL: 'BL',
  SH: 'SH',
  KN: 'KN',
  LC: 'LC',
  MF: 'MF',
  PM: 'PM',
  VC: 'VC',
  WS: 'WS',
  SM: 'SM',
  ST: 'ST',
  SA: 'SA',
  SN: 'SN',
  RS: 'RS',
  SC: 'SC',
  SL: 'SL',
  SG: 'SG',
  SX: 'SX',
  SK: 'SK',
  SI: 'SI',
  SB: 'SB',
  SO: 'SO',
  ZA: 'ZA',
  GS: 'GS',
  SS: 'SS',
  ES: 'ES',
  LK: 'LK',
  SD: 'SD',
  SR: 'SR',
  SJ: 'SJ',
  SE: 'SE',
  CH: 'CH',
  SY: 'SY',
  TW: 'TW',
  TJ: 'TJ',
  TZ: 'TZ',
  TH: 'TH',
  TL: 'TL',
  TG: 'TG',
  TK: 'TK',
  TO: 'TO',
  TT: 'TT',
  TN: 'TN',
  TM: 'TM',
  TC: 'TC',
  TV: 'TV',
  TR: 'TR',
  UG: 'UG',
  UA: 'UA',
  AE: 'AE',
  GB: 'GB',
  UM: 'UM',
  US: 'US',
  UY: 'UY',
  UZ: 'UZ',
  VU: 'VU',
  VE: 'VE',
  VN: 'VN',
  VG: 'VG',
  VI: 'VI',
  WF: 'WF',
  EH: 'EH',
  YE: 'YE',
  ZM: 'ZM',
  ZW: 'ZW',
  AX: 'AX',
} as const satisfies { [K in CountryCode]: K };
