"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.NativeEvent = void 0;
/**
 * 앱에서 발생하는 이벤트 타입
 * */
var NativeEvent = exports.NativeEvent = {
  /** @description 앱에서 인앱결제 처리가 끝남 (성공 | 실패 | 취소) */
  ON_PAYMENT_COMPLETE: 'ON_PAYMENT_COMPLETE',
  /** @description 스토어에서 상품 정보 가져오기 */
  ON_LOAD_SUBSCRIBE_PRODUCTS: 'ON_LOAD_SUBSCRIBE_PRODUCTS',
  /** @description 앱에서 소셜 로그인 완료  */
  ON_COMPLETE_SOCIAL_LOGIN: 'ON_COMPLETE_SOCIAL_LOGIN',
  /** @description 앱에서 선택된 파일들에 대한 이벤트 전송을 받을 떄*/
  ON_LOAD_SELECTED_FILES: 'ON_LOAD_SELECTED_FILES',
  /** @description 사용자가 앱을 끄지않고 백그라운드에 뒀을때 */
  ON_BACKGROUND: 'ON_BACKGROUND',
  /** @description 원본 다운로드 완료 */
  ON_SUCCESS_ORIGIN_DOWNLOAD: 'ON_SUCCESS_ORIGIN_DOWNLOAD',
  /** @description 앱이 백그라운드에서 돌아왔을때 */
  ON_RESUME: 'ON_RESUME',
  /** 디자인 다운로드를 성공했을때 */
  ON_SUCCESS_DESIGN_DOWNLOAD: 'ON_SUCCESS_DESIGN_DOWNLOAD',
  /** 디자인 공유를 성공했을때 */
  ON_SUCCESS_DESIGN_SHARE: 'ON_SUCCESS_DESIGN_SHARE',
  /** 디자인 다운로드나 공유를 실패했을때 */
  ON_FAIL_DESIGN_DOWNLOAD_OR_SHARE: 'ON_FAIL_DESIGN_DOWNLOAD_OR_SHARE',
  ON_LOAD_DEVICE_INFO: 'ON_LOAD_DEVICE_INFO',
  /** @description  앱에서 네이티브 뒤로가기 요청했을 때 */
  ON_NATIVE_BACK: 'ON_NATIVE_BACK',
  ON_CHANGE_USER_SETTING: 'ON_CHANGE_USER_SETTING',
  /** @description  앱 정보(설치된 스토어, 버전, bundleId) 조회 */
  ON_LOAD_APP_INFO: 'ON_LOAD_APP_INFO',
  /** @description  ios 앱 - 유저가 활동 추적 팝업에서 권한 승인 */
  ON_APPROVE_USER_TRACKING_PERMISSION: 'ON_APPROVE_USER_TRACKING_PERMISSION',
  /** @description  화면 최상단으로 스크롤 요청 (ex. 바텀네비게이션에서 현재 탭 터치) */
  ON_SCROLL_TO_TOP: 'ON_SCROLL_TO_TOP',
  /** @description react-native에서 web GA 요청 */
  ON_REQUEST_GA_EVENT: 'ON_REQUEST_GA_EVENT',
  /** @description ios 앱 - 클립보드 데이터 조회 */
  ON_LOAD_CLIPBOARD: 'ON_LOAD_CLIPBOARD'
};